<style lang="scss">
.v-list-item--active {
    border-left: none !important;
    background: #ffffff !important;
}

.mdi-minus-circle-outline{
    color: rgb(49, 240, 11) !important;
}

.mdi-plus-circle-outline{
    color: red !important;
}

.mdi-help-circle-outline{
    color: rgb(255, 187, 0) !important;
}

</style>
<template>
    <div class="c-list-ensayoSeleccionado">

        <v-list>
            <!-- si solo tiene un elemento en items -->
            <v-list-item v-if="item.items.length == 0">
                <v-list-item-icon class="c-list-ensayoSeleccionado__item-icon">
                    <!--<v-icon v-if="item.icono">{{item.icono}}</v-icon>-->
                </v-list-item-icon>
                <v-list-item-title
                class="c-list-ensayoSeleccionado__item-title">
                    {{item.titulo}}
                </v-list-item-title>
            </v-list-item>
            <!-- si tiene varios elementos en items -->
            <v-list-group
                v-else
                :value="true"
                :prepend-icon="item.icono"
            >
                <template v-slot:activator>
                    <v-list-item-title
                    class="c-list-ensayoSeleccionado__item-title">
                        {{item.titulo}}
                    </v-list-item-title>
                </template>

                <template >
                    <v-row align="center" v-for="(fila, indice) in item.items" :key="indice">
                        <v-col
                            cols="6"
                            sm="6"
                            class="c-list-ensayoSeleccionado__item-col
                            c-list-ensayoSeleccionado__item-col--patho"
                        >
                            <v-subheader>{{getParametroName(fila.titulo)}}</v-subheader>
                        </v-col>
                        <v-col
                            cols="6"
                            sm="6"
                            class="c-list-ensayoSeleccionado__item-col"
                        >
                            <v-select
                                class="select-height"
                                :items="disabledResultsOptionsSelec()"
                                :disabled="enableEdit"
                                v-model="fila.resultado"
                                item-text="resultado"
                                item-value="valor"
                                append-icon="mdi-chevron-down"
                                outlined
                                return-object
                                @change="changeResult($event,fila)"
                                dense>
                            </v-select>
                        </v-col>
                    </v-row>
                    <!-- <v-list-item-content>
                        <v-list-item-title
                        v-for="(child, index) in item.items"
                        :key="index">
                            {{child.titulo}}
                        </v-list-item-title>
                    </v-list-item-content> -->
                </template>

            </v-list-group>
        </v-list>
        <v-overlay opacity=".70" :value="loadingResponse" style="z-index: 999999 !important;">
            <div class="loader">
                <div id="dot1"></div>
                <div id="dot2"></div>
                <div id="dot3"></div>
                <div id="dot4"></div>
                <div id="dot5"></div>
                <div id="dot6"></div>
                <div id="dot7"></div>
                <div id="dot8"></div>
                <div id="dot9"></div>
                <div id="dot10"></div>
                <div id="base1"></div>
                <div id="base2"></div>
                <div id="base3"></div>
                <div id="base4"></div>
                <div id="base5"></div>
                <div class="loader-content">
                    <p class="p-body">Cargando...</p>
                </div>
            </div>
        </v-overlay>
    </div>
</template>

<script>
/* eslint-disable */
import { red } from 'color-name';
import { mapState, mapMutations } from 'vuex';
import i18n from '../../plugins/il8n';
export default {
  data: () => ({
    color:'mdi-minus-circle-outline',
    loadingResponse: false,
    result:[
        /*
        {id:1,valor:true,resultado:i18n.t('analisis.positivo')},
        {id:2,valor:false,resultado:i18n.t('analisis.negativo')},
        {id:3,valor:'ZONA GRIS',resultado:i18n.t('analisis.zonagris')},
        {id:4,valor:'REPETIR',resultado:i18n.t('analisis.reproceso')}
        */
    ],
    item: {
      icono: 'mdi-minus-circle-outline',
      titulo: 'Clase 1',
      items: [],
      showItem:true,
    },

  }),
    props: {

        
        
        
        dataItem: {
            type: Object,
            default: null,
        },
        dataEnsayo:{
            type: Object,
            default: null
        },
    },
    async created() {
        this.disabledResultsOptionsSelec();
        //this.$emit('onCloseOverlay');
        //console.log({store:this.pocillosBitacora,edit:this.enableEdit});
        //console.log('ok', this.dataItem);
        this.item.items = await this.getDetalleitem();
        this.item.titulo = this.getTituloitem();
        console.log({ITEMSARRAY:this.item.items,data:this.dataItem})
       
    },
    computed:{
        ...mapState('storeAnalisis', ['pocillosBitacora','enableEdit','parametrosCliente','userInfo']),
    },
    methods: {
        
        getIconoitem(status) {
            console.warn({STATUSSS:status})
            this.item.icono = 'mdi-minus-circle-outline';
            switch (status) {
                case true:
                    this.item.icono = 'mdi-plus-circle-outline';
                    this.color = 'mdi-plus-circle-outline';
                    break;
                case 'REPETIR':
                    this.item.icono = 'mdi-help-circle-outline';
                    this.color = 'mdi-help-circle-outline';
                    break;
              case false:
                this.item.icono = 'mdi-help-circle-outline';
                this.color = 'mdi-help-circle-outline';
                break;
              default:
                this.item.icono = null;
                this.color = null;
                break;
            }
            // mdi-plus-circle-outline,mdi-minus-circle-outline
            
            //return icono;
        },
        getTituloitem() {
            let titulo = `Bajo Riesgo`;
            if(this.dataItem.clase){
                const noshowitems = ['negativo','undefined','vacio','inhibido'];
                if(noshowitems.includes(this.dataItem.clase)){
                    titulo = i18n.t('analisis.'+this.dataItem.clase)//this.dataItem.clase
                }else{
                    switch (this.dataItem.clase) {
                        case 'Error_Perfil':
                            titulo = i18n.t('analisis.repetirReaccion');
                            break;
                        case 'Gris':
                            titulo = i18n.t('analisis.reaccionGris');
                            break;
                        default:

                            break;
                    }
                }
            }else{
                
                if(this.dataItem.resultadofinal){
                  console.log({resultadofinal:this.dataItem.resultadofinal})
                    switch (this.dataItem.resultadofinal) {
                        case 'Positivo':
                            //console.log({ENTRA:this.dataItem.resultadofinal})
                            this.getIconoitem(true)
                            //this.getIconColor(true)
                            titulo = this.dataItem.resultadofinal;
                           
                            break;
                        case 'Negativo':
                          this.getIconoitem(false)
                          break;
                        case 'Vacío':
                            titulo = this.dataItem.resultadofinal;
                          this.getIconoitem(null)
                            break;
                        default:
                            break;
                    }
                }else{
                    //console.log('ENTRA ELSE');
                    titulo = (this.dataItem.ensayo_name!='' ) ? this.dataItem.ensayo_name : ((this.dataItem.hasMix == 'no') ? i18n.t('analisis.vacio') : this.dataEnsayo.name ); // this.dataEnsayo.name;
                }
            }
            //console.log({ELTITULO:titulo})
            return titulo;
        },
        async getDetalleitem() {
           
            
            let itemData = [];
            if(this.dataItem.clase){ // si existe
                switch (this.dataEnsayo.software.kit) {
                    case 'bacterias':
                        itemData = await this.getBacteriapocillo();
                        break;
                    case 'hongos':
                        itemData = await this.getBacteriapocillo();
                        break;
                    case 'food':
                        itemData = await this.getBacteriapocillo();
                        break;
                    default:
                        
                        break;
                }
            }else{
                if(this.dataItem.resultadofinal){
                    switch (this.dataItem.resultadofinal) {
                        case 'Positivo':
                            itemData = await this.getBacteriapocillo();
                            break;
                        default:
                        itemData = [];
                            break;
                    }
                }else{
                    itemData = await this.getBacteriapocillo();
                }
            }
             //console.log({itemData});
             //this.loadingResponse = false;
             //this.overlayChange();
            return itemData;

        },
        getBacteriapocillo(){
            let arrayItem = [];
            const noshowitems = ['negativo','undefined','vacio','inhibido','Error_Perfil','Gris'];
            if(noshowitems.includes(this.dataItem.clase)){
                return [];
            }
            
            for (let i = 1; i < 20; i += 1) {
                if(this.dataItem[`Bac_${i}`] && this.dataItem[`Bac_${i}`].trim()!=''){
                    switch (this.dataEnsayo.software.kit) {
                        
                        case 'food':
                            let resultfinal = (this.dataItem[`Prob_${i}`]) ? i18n.t('analisis.positivo') : i18n.t('analisis.negativo');
                            arrayItem.push({
                                titulo : this.dataItem[`Bac_${i}`],
                                resultado : this.dataItem[`Prob_${i}`],
                                pocillo: this.dataItem[`pocillo`],
                                namePocillo: this.dataItem[`namePocillo`],
                                position: i,
                            })
                            //console.log({PROBABILIDAD:this.dataItem[`Prob_${i}`]})
                            if(this.dataItem[`Prob_${i}`]){
                               this.getIconoitem(this.dataItem[`Prob_${i}`]);
                              

                            }
                        break;
                        default:
                            arrayItem.push({
                                titulo : this.dataItem[`Bac_${i}`] +' '+this.dataItem[`Prob_${i}`]+ '%'
                            })
                            break;
                    }
                    
                }
            }
            return arrayItem;
        },
        changeResult(result,item){
            //console.log({result,item});
            this.pocillosBitacora.map(pocillo => {
                if(pocillo.namePocillo == item.namePocillo){
                    pocillo[`Prob_${item.position}`] = result.valor
                }
            })
            //console.log(this.pocillosBitacora);
        },
       getIconColor(resultado = false){
        let classs='green';
          if(resultado){
            classs = 'red'
          }
          this.color = classs;
          //console.log({COLOR:classs});
       },
        getParametroName(prefijo){
            let parametroName = [];
            parametroName = this.parametrosCliente.find(parametro => parametro.ncorto == prefijo);
            //console.log({parametroName:parametroName});
            return (parametroName == undefined)?prefijo:parametroName.nombre;
        },
        disabledResultsOptionsSelec(){
           
            let result = [];
            if(this.userInfo.bitauth_userdatum.rol_id == 2){

                result = [
                    {id:1,valor:true,resultado:i18n.t('analisis.positivo') },
                    {id:2,valor:false,resultado:i18n.t('analisis.negativo'), disabled:true},
                    {id:3,valor:'ZONA GRIS',resultado:i18n.t('analisis.zonagris'),disabled:true},
                    {id:4,valor:'REPETIR',resultado:i18n.t('analisis.reproceso')}
                ]
            }else{
                result = [
                    {id:1,valor:true,resultado:i18n.t('analisis.positivo') },
                    {id:2,valor:false,resultado:i18n.t('analisis.negativo')},
                    {id:3,valor:'ZONA GRIS',resultado:i18n.t('analisis.zonagris')},
                    {id:4,valor:'REPETIR',resultado:i18n.t('analisis.reproceso')}
                ]
            }
           return result;
        }
    },
};
</script>
